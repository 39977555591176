import React from 'react';
import Layout from '../components/layout';
import PageContainer from '../components/Containers/PageContainer';
import Header from '../components/Header';
import { Grid, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import NewsCard from '../components/Cards/NewsCard';
import { shape } from 'prop-types';
import SIZES from '../constants/sizes';
import COLORS from '../constants/colors';
import { FormattedMessage } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  newsPage: {
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
  title: {
    color: COLORS.DARK_BLUE,
    paddingBottom: 40,
  },
}));

const News = ({ data }) => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO
        title="Scanship | News"
        description="Latest news from Scanship"
        keywords={['Scanship news']}
      />
      <PageContainer>
        <Grid container className={classes.newsPage}>
          <Grid item xs={12}>
            <Header
              sub
              textTransform="lowerCase"
              className={classes.title}
            >
              <FormattedMessage id="News.title" />
            </Header>
          </Grid>
          <Grid item xs={12}>
            <Container style={{ paddingTop: 40 }}>
              <Grid container spacing={5}>
                {data.allContentfulScanshipNewsPost.edges.map(
                  ({ node }) => (
                    <NewsCard
                      data={node}
                      key={node.title + node.publishDate}
                      xs={12}
                      sm={6}
                      md={4}
                    />
                  )
                )}
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </PageContainer>
    </Layout>
  );
};

News.propTypes = {
  data: shape({}).isRequired,
};

export default News;

export const query = graphql`
  query NewsQuery {
    allContentfulScanshipNewsPost(
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          publishDate(formatString: "MMMM Do, YYYY")
          metaDescription
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
